var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mainpage"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"head-title"},[_vm._v("虚拟仿真实验证书")]),_c('div',{staticClass:"retutnbt",on:{"click":_vm.toback}},[_vm._v("返回")])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"maincontain"},[_c('div',{staticClass:"searchcontain"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"maintext",staticStyle:{"margin-left":"15px"}},[_vm._v("关键字:")]),_c('el-input',{staticClass:"elinput",attrs:{"placeholder":"请输入证书名称","clearable":""},model:{value:(_vm.queryParams.keyword),callback:function ($$v) {_vm.$set(_vm.queryParams, "keyword", $$v)},expression:"queryParams.keyword"}})],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"bt searchbt",on:{"click":_vm.searchbt}},[_vm._v("查询")]),_c('div',{staticClass:"bt restbt",on:{"click":_vm.resetbt}},[_vm._v("重置")])])]),_c('el-table',{ref:"singleTable",staticClass:"Table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"highlight-current-row":"","stripe":"","header-cell-style":{
        'font-size': '15px',
        color: '#666666',
        'font-weight': 'bold',
        background: '#FFFFFF',
      },"row-style":{
        'font-size': '15px',
        color: '#222222',
        'font-weight': '400',
      }}},[_c('el-table-column',{attrs:{"prop":"certificate_sn","label":"证书编号"}}),_c('el-table-column',{attrs:{"prop":"vr_name","label":"证书名称"}}),_c('el-table-column',{attrs:{"prop":"send_date","label":"获得时间"}}),_c('el-table-column',{attrs:{"prop":"expiry_date","label":"有效期"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("dataformat")(scope.row.expiry_date))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticClass:"opreatbt",on:{"click":function($event){return _vm.viewcertificate(scope.row)}}},[_vm._v("查看证书")])]}}])})],1),_c('div',{staticClass:"elpagination"},[_c('div',{staticClass:"elpagination-title"},[_vm._v("共"+_vm._s(_vm.total)+"条")]),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"page-size":10,"current-page":_vm.queryParams.page},on:{"current-change":_vm.pageChange}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }